import { Bar, Button, Dialog, Title } from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import getPropertyRentalUnitsTableContentDefinitions from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'
import styles from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableEditDialog.module.css'
import PropertyRentalUnitsWorkingVersionTableEditDialogContent from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableEditDialogContent'
import { useMassEditDefaultOptions } from 'hooks/services/properties/rent-roll/working-version/useMassEditDefaultOptions'

/**
 * use ui-components dialog | https://fioneer.atlassian.net/browse/CWP-13200
 */
const PropertyRentalUnitsWorkingVersionTableEditDialog = ({
  isOpen,
  setEditDialogIsOpen,
  rowsToEdit,
  segments,
  handleMassEdit,
  isMultiProperty,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.table',
  })
  const { t: tButton } = useTranslation('translation', { keyPrefix: 'buttons' })

  const { KEEP_VALUES, CLEAR_VALUES } = useMassEditDefaultOptions()

  const dialogTitle = rowsToEdit?.length
    ? t('edit-dialog.title') + ' (' + rowsToEdit.length + ')'
    : t('edit-dialog.title')

  const isTruthyOrZero = (value) => value || value === 0

  const determineInitialChoice = useCallback(
    (contentKey) =>
      _.uniqBy(rowsToEdit, `original.${contentKey}.value`).length === 1 &&
      isTruthyOrZero(rowsToEdit[0].original[contentKey].value)
        ? rowsToEdit[0].original[contentKey].value
        : KEEP_VALUES,
    [KEEP_VALUES, rowsToEdit],
  )

  const initialMassEditChoices = useCallback(() => {
    const initialChoices = {}
    getPropertyRentalUnitsTableContentDefinitions(t, isMultiProperty).forEach(
      (contentDefinition) =>
        (initialChoices[contentDefinition.contentKey] = determineInitialChoice(
          contentDefinition.contentKey,
        )),
    )
    return { ...initialChoices }
  }, [determineInitialChoice, isMultiProperty, t])

  const [massEditChoices, setMassEditChoices] = useState({ ...initialMassEditChoices() })
  const [isInitialized, setIsInitialized] = useState(false)
  useEffect(() => {
    if (!isOpen) {
      setIsInitialized(false)
      return
    }
    if (isOpen && !isInitialized) {
      setMassEditChoices({ ...initialMassEditChoices() })
      setIsInitialized(false)
    }
  }, [initialMassEditChoices, isInitialized, isOpen])
  const [validInputs, setValidInputs] = useState({})

  const handleOnChange = ({ contentKey, choice, isValidInput = true }) => {
    setMassEditChoices((prevMassEditChoice) => ({ ...prevMassEditChoice, [contentKey]: choice }))
    setValidInputs((prevInvalidInputs) => ({
      ...prevInvalidInputs,
      [contentKey]: isValidInput,
    }))
  }

  const getChangeInstructions = () =>
    Object.entries(massEditChoices)
      .filter((editChoice) => editChoice[1] !== null && editChoice[1] !== KEEP_VALUES)
      .map((editChoice) => {
        const contentKey = editChoice[0]
        const chosenValue = editChoice[1]
        if (chosenValue === CLEAR_VALUES) {
          return { contentKey: contentKey, value: null }
        }
        return { contentKey: contentKey, value: chosenValue }
      })

  const handleAcceptChanges = () => {
    const rowKeys = rowsToEdit.map((row) => row.original.rowKey)
    handleMassEdit({ rowKeys: [...rowKeys], changeInstructions: [...getChangeInstructions()] })
    setEditDialogIsOpen(false)
  }

  return (
    <Dialog
      draggable
      resizable
      className={styles['mass-edit-dialog']}
      open={isOpen}
      onAfterClose={() => setEditDialogIsOpen(false)}
      header={
        <Bar>
          <Title>{dialogTitle}</Title>
        </Bar>
      }
      footer={
        <Bar
          endContent={
            <>
              <Button
                id={'rent-roll-working-version-mass-edit-accept'}
                design="Emphasized"
                onClick={handleAcceptChanges}
                disabled={!Object.values(validInputs).every(Boolean)}
              >
                {tButton('save')}
              </Button>
              <Button
                id={'rent-roll-working-version-mass-edit-cancel'}
                onClick={() => setEditDialogIsOpen(false)}
              >
                {tButton('close')}
              </Button>
            </>
          }
        />
      }
    >
      <PropertyRentalUnitsWorkingVersionTableEditDialogContent
        handleOnChange={handleOnChange}
        massEditChoices={massEditChoices}
        rowsToEdit={rowsToEdit}
        segments={segments}
        isMultiProperty={isMultiProperty}
      />
    </Dialog>
  )
}

PropertyRentalUnitsWorkingVersionTableEditDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setEditDialogIsOpen: PropTypes.func.isRequired,
  rowsToEdit: PropTypes.array,
  segments: PropTypes.array,
  handleMassEdit: PropTypes.func.isRequired,
  isMultiProperty: PropTypes.bool.isRequired,
}

export default PropertyRentalUnitsWorkingVersionTableEditDialog
